import * as React from 'react';
import { Link } from 'gatsby';
import AnimatedHeading from '../components/AnimatedHeading';
import SEO from '../components/SEO';

// markup
const NotFoundPage = () => {
	return (
		<>
			<SEO
				title="404"
				pathname="/404"
				desc="Sorry 😔 we couldn't find what you were looking for."
			/>
			<AnimatedHeading text="Page not found" />
			<div class="p-6 md:p-8 richtext">
				<p>
					Sorry{' '}
					<span role="img" aria-label="Pensive emoji">
						😔
					</span>{' '}
					we couldn't find what you were looking for.
				</p>
				<Link to="/" className="link">
					Go home
				</Link>
			</div>
		</>
	);
};

export default NotFoundPage;
